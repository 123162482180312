import { signal } from '@preact/signals-react';

export const procedimentos = signal([]);

export const procedimentoSelecionado = signal([])

export const processo = signal(null);

export const valoresIniciais = signal(null);

export const instituicaoSolicitanteSigtap = signal([]);

export const instituicaoAutorizadoraSigtap = signal([]);