import * as yup from 'yup';
import {
    FIELDS
} from './fieldNames';
import { MYME_TYPE_PERMITIDOS, TIPO_ANEXO_SIGLA } from '../../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [FIELDS.ANEXOS]: yup.array().of(
            yup.object().shape({
                [FIELDS.ANEXO]: yup
                    .mixed()
                    .nullable()
                    .test('formatoArquivo', strings.formatoArquivoInvalido, (value) => {
                        if (!value) {
                            return true;
                        }

                        if (value && (!value.type || value.type == '')) {
                            return true;
                        }
                        return value && MYME_TYPE_PERMITIDOS.includes(value.type);
                    })
            }))
            .test('Ao menos um anexo obrigatório', strings.necessitaAnexo, function () {
                const { from } = this;
                return from[0].value.anexos[0].anexo;
            })
            .required(strings.campoObrigatorio)
    });
}
