import * as yup from 'yup';
import {
    FIELDS
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [FIELDS.PROCEDIMENTO]: yup.object().shape({
            [FIELDS.PROCEDIMENTO]: yup
                .object().shape({
                    ano_mes_competencia: yup.number(),
                    codigo: yup.number(),
                    nome: yup.string(),
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.QUANTIDADE]: yup
                .number()
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.INSTITUICAO_SOLICITANTE]: yup.object()
                .shape({
                    id: yup.number(),
                    nome: yup.string()
                })
                .nullable()
                .required(strings.campoObrigatorio),
            [FIELDS.INSTITUICAO_AUTORIZADORA]: yup.object()
                .when(FIELDS.PROCEDIMENTO, {
                    is: (val) => {
                        return val && val.id !== -1;
                    },
                    then: () => yup.object()
                        .shape({
                            id: yup.number(),
                            nome: yup.string()
                        })
                        .nullable()
                        .required(strings.campoObrigatorio)
                }).nullable(),
            [FIELDS.NOVO_PROCEDIMENTO]: yup.object()
                .when(FIELDS.PROCEDIMENTO, {
                    is: (val) => {
                        return val && val.id === -1;
                    },
                    then: () => yup.object()
                        .shape({
                            ano_mes_competencia: yup.number(),
                            codigo: yup.number(),
                            nome: yup.string(),
                        })
                        .nullable()
                        .required(strings.campoObrigatorio)
                }).nullable(),
            [FIELDS.INSTITUICAO_EXECUTORA]: yup.object()
                .when(FIELDS.PROCEDIMENTO, {
                    is: (val) => {
                        return val && val.id === -1;
                    },
                    then: () => yup.object()
                        .shape({
                            id: yup.number(),
                            nome: yup.string()
                        })
                        .nullable()
                        .required(strings.campoObrigatorio)
                }).nullable(),
        }).required()
    });
}

